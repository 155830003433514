import { FeatureCollection, Feature, LineString, Position } from "geojson";
import { LngLatBounds } from "mapbox-gl";

class MapFunctions {
  static getBoundingBox(points: Omit<Point, "id" | "timestamp">[], slice = 10) {
    if (points.length == 0) return;

    const boundedPoints = points.slice(-slice);

    return boundedPoints.reduce(
      (bounds, point) => bounds.extend(point.location.coordinates),
      new LngLatBounds(
        boundedPoints[0].location.coordinates,
        boundedPoints[0].location.coordinates
      )
    );
  }

  static initGeojson(): FeatureCollection {
    return {
      type: "FeatureCollection",
      features: [],
    };
  }

  static geoJsonFromCoords(coordinates: Position[]): Feature<LineString> {
    const feature: Feature<LineString> = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates,
      },
    };

    return feature;
  }
}

export default MapFunctions;
