import { DateTime } from "luxon";

export function dateTimeText(timestamp: string, timezone: string | undefined) {
  let html = `${DateTime.fromISO(timestamp)
    .setZone(timezone)
    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`;
  const local = DateTime.fromISO(timestamp)
    .setZone(timezone)
    .toLocaleString(DateTime.TIME_SIMPLE);
  const userTime = DateTime.fromISO(timestamp).toLocaleString(
    DateTime.TIME_SIMPLE
  );

  if (local != userTime) {
    html += "<br />";
    html += `Local: ${local}`;
    html += "<br />";
    html += `You: ${userTime}`;
  } else {
    html += local;
  }

  return html;
}
